<template>
    <v-container
            id="user-profile"
            fluid
            tag="section"
    >
        <v-row justify="center">
            <v-col
                    cols="12"
                    md="10"
            >
                <base-material-card>
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Form Barang
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Tambah / Ubah data Barang
                        </div>
                    </template>

                    <v-form ref="form">
                        <v-container class="py-0">
                            <v-row>
                                <input type="hidden" name="id" id="id" v-model="barang.id"/>
                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            class="purple-input"
                                            label="Nama"
                                            v-model="barang.name"
                                    />
                                </v-col>

                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-text-field
                                            label="Kategori"
                                            class="purple-input"
                                            readonly
                                            v-model="barang.category_name"
                                    >
                                        <v-icon slot="append" color="red" size="30" @click="openModalKategori">mdi-magnify</v-icon>
                                    </v-text-field>
                                </v-col>
                                <!--<v-col-->
                                        <!--cols="12" md="1">-->
                                    <!--<v-btn color="secondary" raised x-small dark v-on:click="openModalKategori">-->
                                        <!--<v-icon>mdi-television</v-icon>-->
                                    <!--</v-btn>-->
                                <!--</v-col>-->

                                <v-col
                                        cols="12"
                                        md="5"
                                >
                                    <v-text-field
                                            label="Stok"
                                            class="purple-input"
                                            v-model="barang.stock"
                                            readonly
                                    />
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="4"
                                >
                                    <v-text-field
                                            label="Satuan"
                                            class="purple-input"
                                            readonly
                                            v-model="barang.uom_name"
                                    >
                                        <v-icon slot="append" color="red" size="30" @click="openModalUom">mdi-magnify</v-icon>
                                    </v-text-field>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="3"
                                >
                                    <v-checkbox
                                            v-model="barang.is_direct_selling"
                                            label="Langsung Jual"
                                    ></v-checkbox>
                                </v-col>
                                <v-col
                                        cols="12"
                                        md="6"
                                >
                                    <v-textarea
                                            label="Deskripsi"
                                            class="purple-input"
                                            v-model="barang.desc"
                                    />
                                </v-col>


                                <v-col
                                        cols="12"
                                        class="text-right"
                                >
                                    <v-btn
                                            color="success"
                                            class="mr-0"
                                            @click="simpan"
                                    >
                                        Simpan
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </base-material-card>
                <v-dialog v-model="modalKategori" persistent min-width="350" max-width="50%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">
                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Kategori Barang
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalKategori = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchKategori"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersKategori"
                                            :items="itemsKategori"
                                            :search="searchKategori"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihKategori(item)">
                                            mdi-check-bold
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalKategori = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="modalUom" persistent min-width="350" max-width="50%">
                    <v-container>
                        <v-row>
                            <v-col>
                                <base-material-card>
                                    <template v-slot:heading>
                                        <div class="display-2 font-weight-light">
                                            <v-row>
                                                <v-col cols="12" md="11">
                                                    Daftar Satuan Stok
                                                </v-col>
                                                <v-col cols="12" md="1" class="text-right">
                                                    <v-icon @click="modalUom = false">mdi-close-circle</v-icon>
                                                </v-col>
                                            </v-row>
                                        </div>

                                        <div class="subtitle-1 font-weight-light">
                                        </div>
                                    </template>
                                    <v-row>
                                        <v-col cols="12"
                                               lg="6">
                                            <v-text-field
                                                    v-model="searchUom"
                                                    label="Search"
                                                    single-line

                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="py-1"/>
                                    <v-data-table
                                            :headers="headersUom"
                                            :items="itemsUom"
                                            :search="searchUom"
                                    >
                                        <template v-slot:item.action="{ item }">
                                            <v-icon class="mx-1" color="green darken-2" @click="pilihUom(item)">
                                                mdi-check-bold
                                            </v-icon>
                                        </template>
                                    </v-data-table>

                                    <v-row justify="end">
                                        <v-col cols="12"
                                               lg="2">
                                            <v-btn color="blue darken-1" text @click="modalUom = false">Tutup
                                            </v-btn>
                                            <v-spacer></v-spacer>
                                        </v-col>
                                    </v-row>


                                </base-material-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-dialog>
                <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-text class="text-center">
                            Loading
                            <v-spacer></v-spacer>
                            <v-progress-linear
                                    indeterminate
                                    rounded
                                    color="yellow darken-2"
                            ></v-progress-linear>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-snackbar
                        v-model="snackbar"
                        :color="color"
                        :top="true"
                >
                    <span>{{message}}</span>
                    <v-btn
                            dark
                            icon
                            @click="snackbar = false"

                        <v-icon>
                            $vuetify.icons.cancel
                        </v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {ApiUrl} from '../../../../config/constant'
    import axios from 'axios'
    import {
        mapState,
    } from 'vuex'

    export default {
        name: 'FormGoods',
        data() {
            return {
                snackbar: false,
                dialog: false,
                message: '',
                color: 'success',
                barang: {
                    id: null,
                    fk_goods_category_id: 0,
                    category_name: '',
                    name: '',
                    desc: '',
                    stock: 0,
                    fk_uom_id: 0,
                    uom_name: '',
                    is_direct_selling: 0,
                },
                modalKategori: false,
                searchKategori: '',
                headersKategori: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 200,
                    },
                    {
                        sortable: true,
                        text: 'Deskripsi',
                        filterable: true,
                        value: 'desc',
                        align: 'start',
                        width: 200,
                    },
                    {
                        sortable: false,
                        text: 'Pilih',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsUom: [],
                modalUom: false,
                searchUom: '',
                headersUom: [
                    {
                        sortable: false,
                        text: 'No',
                        value: 'no',
                        align: 'center',
                        width: 50,
                    },
                    {
                        sortable: true,
                        text: 'Nama',
                        filterable: true,
                        value: 'name',
                        width: 300,
                    },
                    {
                        sortable: true,
                        text: 'Kode',
                        filterable: true,
                        value: 'code',
                        align: 'start',
                        width: 100,
                    },
                    {
                        sortable: false,
                        text: 'Pilih',
                        value: 'action',
                        align: 'center',
                        width: 70
                    },
                ],
                itemsKategori: [],
            }
        },
        computed: {
            ...mapState(['currentUser']),
        },
        mounted: function () {
            this.dataKategori()
            this.dataUom()
            let id = this.$route.params.id
            console.log('edit')
            console.log(id)
            if(id !== undefined){
                this.dataBarang(id)
            }

        },
        methods: {
            openModalKategori: function () {
                this.modalKategori = true
            },
            openModalUom: function () {
                this.modalUom = true
            },
            dataKategori: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'goodscategory', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsKategori = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihKategori: function (item) {
                console.log('pilih kategori')
                console.log(item)
                this.barang.category_name = item.name
                this.barang.fk_goods_category_id = item.id
                this.modalKategori = false
            },
            dataBarang: function(id){
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'goods/'+id, headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.barang = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            dataUom: function (e) {
                let token = this.currentUser.token;
                let header = {
                    'Authorization': 'Bearer ' + token,
                    'Content-type': 'application/json'
                }
                axios
                ({method: 'get', url: ApiUrl + 'uom', headers: header})
                    .then(response => {
                        console.log(response)
//                        this.dialog = false
                        if (response.data.success) {
                            this.itemsUom = response.data.data
                        }
                    })
                    .catch(error => (
                        console.log(error)
                    ))
            },
            pilihUom: function (item) {
                console.log('pilih uom')
                console.log(item)
                this.barang.uom_name = item.name
                this.barang.fk_uom_id = item.id
                this.modalUom = false
            },
            simpan: function () {
                if(this.barang.name && this.barang.name !== '' && this.barang.fk_goods_category_id && this.barang.fk_goods_category_id !== 0 &&
                    this.barang.fk_uom_id && this.barang.fk_uom_id !== 0) {
                    let token = this.currentUser.token;
                    let header = {
                        'Authorization': 'Bearer ' + token,
                        'Content-type': 'application/json'
                    }
                    let data = {
                        fk_goods_category_id: this.barang.fk_goods_category_id,
                        name: this.barang.name,
                        desc: this.barang.desc,
                        stock: this.barang.stock,
                        fk_uom_id: this.barang.fk_uom_id,
                        is_direct_selling: this.barang.is_direct_selling,
                        id: this.barang.id
                    }
                    this.dialog = true
                    axios
                    ({method: 'post', url: ApiUrl + 'goods/simpan', data: data, headers: header})
                        .then(response => {
                            console.log(response)
                            this.dialog = false
                            if (response.data.success) {
                                this.snackbar = true
                                this.color = 'success'
                                this.message = 'Berhasil simpan                    '
                                this.barang = {
                                    fk_goods_category_id: 0,
                                    category_name: '',
                                    name: '',
                                    desc: '',
                                    stock: 0,
                                    fk_uom_id: 0,
                                    uom_name: '',
                                    is_direct_selling: 0,
                                    id: null,
                                }
                                this.$refs.form.resetValidation()
                            } else {
                                this.snackbar = true
                                this.color = 'error'
                                this.message = 'Gagal simpan                    '
                            }
                        })
                        .catch(error => {
                            this.dialog = false
                            console.log(error)
                        })
                } else {
                    this.snackbar = true
                    this.color = 'error'
                    this.message = 'Nama, kategori dan satuan stok harus diisi'
                }
            },
        }
    }
</script>
